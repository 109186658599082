import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ProtocolosService } from 'src/app/services/protocolos.service';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { HistoricoModalComponent } from 'src/app/components/historico-modal/historico-modal.component';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-table-list-protocols',
  templateUrl: './table-list-protocols.component.html',
  styleUrls: ['./table-list-protocols.component.scss']
})
export class TableListProtocolsComponent implements OnInit {

  @ViewChild('radioInput') radioInput!: ElementRef<HTMLInputElement>;
  @Output() protocol = new EventEmitter<any>();
  checked: boolean = false;
  @Input() name?: string;
  @Input() creationDate?: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() modalRequest!: boolean;
  dataSource: any;
  pageIndex: number = 0;
  pageSize: number = 5;
  length: number = 0;
  protocols: any;
  loading: any;
  displayedColumns: string[] = [];
  radioChecked: boolean = false;
  protocolSelected: any;

  constructor(public dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private protocoloService: ProtocolosService) {
  }


  ngOnInit(): void {
    this.loading = true;
    this.displayedColumns = this.modalRequest ? [
      'checkboxBtn',
      'creationDate',
      'protocolName',
      'dataRecorrencia',
      'actions',
    ] : [
      'creationDate',
      'protocolName',
      'dataRecorrencia',
      'isActive',
      'actions',
    ];

    if (this.modalRequest) {
      this.getProtocols(this.name, this.creationDate, 5, 0);
    }
    else {
      this.listProtocols();
    }

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  changeStatusProtocol(element: any) {
    if (!this.modalRequest) {
      if (element.active) {
        this.dialog.open(ModalConfirmComponent, {
          data: {
            default: true,
            text: 'Deseja desativar o protocolo?'
          },
        }).afterClosed().subscribe(result => {

          if (result.confirm) {
            this.protocoloService.inactivateProtocol(element.hash).subscribe(r => {
              this.dialog.open(ModalConfirmComponent, {
                data: {
                  success: true,
                  successText: 'Protocolo foi inativado!',
                }
              });
              location.reload();
            }, error => {
              this.dialog.open(ModalConfirmComponent, {
                data: {
                  error: true,
                  errorText: 'Erro ao salvar!',
                },
              });
            })
          }
        });
      }
      else {
        this.dialog.open(ModalConfirmComponent, {
          data: {
            default: true,
            text: 'Deseja ativar o protocolo?'
          },
        }).afterClosed().subscribe(result => {
          if (result.confirm) {
            this.protocoloService.activateProtocol(element.hash).subscribe(r => {
              this.dialog.open(ModalConfirmComponent, {
                data: {
                  success: true,
                  successText: 'Protocolo foi ativado!',
                }
              });
              location.reload();
            }, error => {
              this.dialog.open(ModalConfirmComponent, {
                data: {
                  error: true,
                  errorText: 'Erro ao salvar!',
                },
              });
            })
          }
        });
      }
    }
  }

  novoProtocolo(id?: any) {
    if (id) {
      this.router.navigate(['/safe-gis/protocolos/formulario-protocolo', id]);
    }
    else {
      this.router.navigate(['/safe-gis/protocolos/formulario-protocolo']);
    }
  }

  showHistory(id: any) {
    this.dialog.open(HistoricoModalComponent, {
      data: id
    });
  }

  listProtocols(pageEvent?: PageEvent): void {
    if (pageEvent) {
      this.pageIndex = pageEvent.pageIndex;
      this.pageSize = pageEvent.pageSize;
    } else {
      this.pageIndex = 0;
      this.pageSize = 15;
    }



    this.getProtocols(this.name, this.creationDate, this.pageSize, this.pageIndex);
  }

  getProtocols(name?: any, date?: any, size?: any, index?: any) {

    const options = {
      name: name ? name : '',
      date: date ? date : '',
      size: size ? size : '',
      page: index ? index : ''
    }

    this.protocoloService.getProtocols(options, this.modalRequest ? true : false).subscribe((r: any) => {
      this.protocols = r.content;
      this.length = r.totalItems;
      this.dataSource = new MatTableDataSource(this.protocols);
      this.loading = false;
    });
  }

  getProtocolSelected(protocol: any) {
    this.protocolSelected = protocol;
    this.protocol.emit(this.protocolSelected);
  }


}
