import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProtocolosService {

  httpOptions: any;
  customerId: any;
  storage: Storage;
  token: any;

  private translationMap: { [key: string]: string } = {
    'Terras Indígenas': 'indigenousLands',
    'Trabalho Escravo': 'slavery',
    'Embargo Ibama': 'ibamaEmbargo',
    'Deter': 'deter',
    'Prodes Amazônia': 'prodesAmazon',
    'Prodes Mata Atlântica': 'prodesAtlanticForest',
    'Prodes Caatinga': 'prodesCaatinga',
    'Prodes Cerrado': 'prodesCerrado',
    'Prodes Pampa': 'prodesPampa',
    'Prodes Pantanal': 'prodesPantanal',
    'Áreas Quilombolas': 'quilombolaAreas',
    'Terra Indígena': 'indigenousLand',
    'Unidade de Consevação': 'conservationUnit',
    'Sítios Arqueológicos': 'archeologicalSites',
    'Florestas públicas': 'publicForests',
    'Áreas de mineração': 'miningAreas',
    'Queimadas': 'burned',
    'LDI Pará': 'ldi',
    'ICMBio': 'icmBio',
    'Map Biomas': 'mapBiomas',
    'Alerta Map Biomas': 'mapBiomasWarning',
    'CEIS': 'ceis',
    'CND FGTS': 'cndFgts',
    'CND Criminal': 'cndCriminal',
    'CND Trabalhista': 'cndTrabalhista',
    'Distribuição Criminal': 'distribuicaoCriminal',
    'Vegetação': 'vegetacao',
    'RenovaBio': 'renovaBio',
    'Bioma': 'bioma',
    'Área': 'area',
    'Soja': 'soja',
    'Status': 'status'
  };

  constructor(private http: HttpClient) {
    this.storage = window.sessionStorage;

    const item = this.storage.getItem('token');
    this.token = item ? JSON.parse(item) : {};

    const itemUser = this.storage.getItem('user');
    const user = itemUser ? JSON.parse(itemUser) : {};
    this.customerId = user.customerId;
  }

  translateCriteria(input: string): string {
    if (this.translationMap[input]) {
      return this.translationMap[input];
    }

    const reversedMap = Object.keys(this.translationMap).reduce((acc, key) => {
      acc[this.translationMap[key]] = key;
      return acc;
    }, {} as { [key: string]: string });

    return reversedMap[input] || input;
  }

  getAllCriteriasNamesReturneds() {
    return [
      'burned',
      'slavery',
      'ibamaEmbargo',
      'deterAmazon',
      'deterCerrado',
      'deterPantanal',
      'prodesAmazon',
      'prodesAtlanticForest',
      'prodesCaatinga',
      'prodesCerrado',
      'prodesPampa',
      'prodesPantanal',
      'publicForests',
      'quilombolaAreas',
      'indigenousLand',
      'conservationUnit',
      'archeologicalSites',
      'miningAreas',
      'ldiManual',
      'ldiAutomatic',
      'icmBio',
      'ceis',
      'cndFgts',
      'cndCriminal',
      'cndTrabalhista',
      'distribuicaoCriminal',
      'mapBiomasWarning',
      'icmBioEmbargo',
      'icmBioInfraction',
      /*'icmBioEmbargoByProducer',
      'icmBioInfractionByProducer'*/
    ];
  }

  getAllLayersMapName(): any {
    const layersName = [
      { name: 'icmBio', description: 'ICMBio' },
      { name: 'icmBioEmbargo', description: 'Embargo ICMBio' },
      { name: 'icmBioInfractions', description: 'Infrações ICMBio' },
      /* { name: 'icmBioEmbargoByProducer', description: 'Embargo ICMBio por Produtor' },
       { name: 'icmBioInfractionByProducer', description: 'Infrações ICMBio por Produtor' },*/
      { name: 'archeologicalSitesArea', description: 'Sítios Arqueológicos Área' },
      { name: 'conservationUnit', description: 'UC' },
      { name: 'kml', description: 'KML' },
      { name: 'ldiAutomatic', description: 'LDI Automatizado' },
      { name: 'ldiManual', description: 'LDI Manual' },
      { name: 'deterAmazon', description: 'Deter Amazônia' },
      { name: 'deterCerrado', description: 'Deter Cerrado' },
      { name: 'deterPantanal', description: 'Deter Pantanal' },
      { name: 'ibamaEmbargo', description: 'Embargo do IBAMA' },
      { name: 'indigenousLand', description: 'Terra indígena' },
      { name: 'prodesAmazon', description: 'Prodes Amazônia' },
      { name: 'prodesAtlanticForest', description: 'Prodes Mata Atlântica' },
      { name: 'prodesCaatinga', description: 'Prodes Caatinga' },
      { name: 'prodesCerrado', description: 'Prodes Cerrado' },
      { name: 'prodesPampa', description: 'Prodes Pampa' },
      { name: 'prodesPantanal', description: 'Prodes Pantanal' },
      { name: 'publicForests', description: 'Florestas públicas' },
      { name: 'quilombolaAreas', description: 'Áreas quilombolas' },
      { name: 'property', description: 'Limite do imóvel' },
      { name: 'editing', description: '⁠Polígonos de interesse' },
      { name: 'vegetacao_MT', description: 'Vegetação' },
      { name: 'vegetacao_PR', description: 'Vegetação' },
      { name: 'vegetacao_RS', description: 'Vegetação' },
      { name: 'soja_MT', description: 'Soja' },
      { name: 'soja_PR', description: 'Soja' },
      { name: 'soja_RS', description: 'Soja' },
      { name: 'renovaBio', description: 'RenovaBio' },
      { name: 'mapBiomasWarning', description: 'Alerta Map Biomas' },
      { name: 'miningAreas', description: 'Área de mineração' }
    ];

    return layersName;

  }

  getLegendColor(layerName: string): string {
    switch (layerName) {
      case 'archeologicalSitesArea':
        return 'pink';
      case 'conservationUnit':
        return '#006400';
      case 'kml':
        return 'white';
      case 'deterAmazon':
        return 'orange';
      case 'deterCerrado':
        return 'orange';
      case 'deterPantanal':
        return 'orange';
      case 'ibamaEmbargo':
        return 'purple';
      case 'indigenousLand':
        return 'brown';
      case 'prodesAmazon':
        return 'yellow';
      case 'prodesAtlanticForest':
        return 'yellow';
      case 'prodesCaatinga':
        return 'yellow';
      case 'prodesCerrado':
        return 'yellow';
      case 'prodesPampa':
        return 'yellow';
      case 'prodesPantanal':
        return 'yellow';
      case 'quilombolaAreas':
        return 'gray';
      case 'property':
        return 'blue';
      case 'editing':
        return 'black';
      case 'mapBiomasWarning':
        return 'red';
      case 'vegetacao_MT':
        return 'green';
      case 'vegetacao_PR':
        return 'green';
      case 'vegetacao_RS':
        return 'green';
      case 'soja_MT':
        return '#CCCC66';
      case 'soja_PR':
        return '#CCCC66';
      case 'soja_RS':
        return '#CCCC66';
      case 'renovaBio':
        return '#CCFFCC';
      case 'miningAreas':
        return '#BDB76B';
      case 'publicForests':
        return '#556B2F';
      case 'burned':
        return '#FF6347';
      case 'ldiAutomatic':
        return '#4682B4';
      case 'ldiManual':
        return '#DAA520';
      case 'icmBio':
        return '#F0E68C';
      case 'icmBioEmbargo':
        return '#FF1493';
      case 'icmBioInfractions':
        return '#4B0082';
      case 'icmBioEmbargoByProducer':
        return '#32CD32';
      case 'icmBioInfractionByProducer':
        return '#FF8C00';

      default:
        return 'white';
    }
  }

  getCriteriaReturnedName(type: string): string {
    let result: string;

    switch (type) {
      case 'indigenousLands':
        result = 'Terras Indígenas'
        break;
      case 'burned':
        result = 'Queimadas';
        break;
      case 'mapBiomasWarning':
        result = 'Alerta Map Biomas';
        break;
      case 'mapBiomas':
        result = 'Map Biomas';
        break;
      case 'archeologicalSites':
        result = 'Sitios Arqueológicos';
        break;
      case 'archeologicalSitesArea':
        result = 'Sitios Arqueológicos Área';
        break;
      case 'property':
        result = 'Propriedade';
        break;
      case 'editing':
        result = 'Area resumida';
        break;
      case 'conservationUnit':
        result = 'Unidade de Conservação';
        break;
      case 'deterAmazon':
        result = 'Deter Amazônia';
        break;
      case 'deterCerrado':
        result = 'Deter Cerrado';
        break;
      case 'deterPantanal':
        result = 'Deter Pantanal';
        break;
      case 'ibamaEmbargo':
        result = 'Embargo Ibama';
        break;
      case 'indigenousLand':
        result = 'Terra Indígena';
        break;
      case 'prodesAmazon':
        result = 'Prodes Amazônia';
        break;
      case 'prodesAtlanticForest':
        result = 'Prodes Mata Atlântica';
        break;
      case 'prodesCaatinga':
        result = 'Prodes Caatinga';
        break;
      case 'prodesCerrado':
        result = 'Prodes Cerrado';
        break;
      case 'prodesPampa':
        result = 'Prodes Pampa';
        break;
      case 'prodesPantanal':
        result = 'Prodes Pantanal';
        break;
      case 'publicForests':
        result = 'Florestas Públicas';
        break;
      case 'quilombolaAreas':
        result = 'Áreas Quilombolas';
        break;
      case 'slavery':
        result = 'Trabalho Escravo';
        break;
      case 'miningAreas':
        result = 'Área de Mineração';
        break;
      case 'ldiAutomatic':
        result = 'LDI Automatizado';
        break;
      case 'ldiManual':
        result = 'LDI Manual';
        break;
      case 'icmBio':
        result = 'ICMBio';
        break;
      case 'cndCriminal':
        result = 'CND Criminal';
        break;
      case 'ceis':
        result = 'CEIS';
        break;
      case 'cndFgts':
        result = 'CND FGTS';
        break;
      case 'cndTrabalhista':
        result = 'CND Trabalhista';
        break;
      case 'distribuicaoCriminal':
        result = 'Distribuição Criminal';
        break;
      case 'vegetacao_MT':
      case 'vegetacao_PR':
      case 'vegetacao_RS':
        result = 'Vegetação';
        break;
      case 'soja_MT':
      case 'soja_PR':
      case 'soja_RS':
        result = 'Soja';
        break;
      case 'renovaBio':
        result = 'RenovaBio';
        break;
      case 'status':
        result = 'Status Car';
        break;
      case 'area':
        result = 'Área';
        break;
      case 'bioma':
        result = 'Bioma';
        break;
      default:
        result = '';
        break;
      case 'icmBioInfraction':
        result = 'Infrações ICMBio';
        break;
      case 'icmBioEmbargo':
        result = 'Embargo ICMBio';
        break;
      /*case 'icmBioEmbargoByProducer':
        result = 'Embargo ICMBio por Produtor';
        break;
      case 'icmBioInfractionByProducer':
        result = 'Infrações ICMBio por Produtor';
        break;*/
    }

    return result;
  }


  getProtocols(options: { name?: any, date?: any, page?: any, size?: any }, modalResquest?: boolean) {
    let params = new HttpParams();

    if (options.name) {
      params = params.set('name', options.name);
    }

    if (options.date) {
      params = params.set('createdAt', options.date);
    }

    if (options.page) {
      params = params.set('page', options.page);
    }

    if (options.size) {
      params = params.set('size', options.size);
    }

    if (modalResquest)
      params = params.set('onlyActive', true);
    else
      params = params.set('onlyActive', false);

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params,
    };

    return this.http.get(`${environment.api}/protocol-service/list/filtered/${this.customerId}`, httpOptions);
  }

  getProtocolsByProvider(options: { name?: any, date?: any, page?: any, size?: any }, customerId: any) {
    let params = new HttpParams();

    if (options.name) {
      params = params.set('name', options.name);
    }

    if (options.date) {
      params = params.set('createdAt', options.date);
    }

    if (options.page) {
      params = params.set('page', options.page);
    }

    if (options.size) {
      params = params.set('size', options.size);
    }

    params = params.set('onlyActive', false);

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params,
    };

    return this.http.get(`${environment.api}/protocol-service/list/filtered/${customerId}`, httpOptions);
  }

  getProtocolByHash(hash?: any) {
    let params = new HttpParams();

    params = params.set('onlyActive', false);

    if (hash) {
      params = params.set('id', hash);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params,
    };

    return this.http.get(`${environment.api}/protocol-service/list/filtered/${this.customerId}`, httpOptions);
  }

  getProtocolByHashByProvider(hash?: any, customerId?: any) {
    let params = new HttpParams();

    params = params.set('onlyActive', false);

    if (hash) {
      params = params.set('id', hash);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params,
    };

    return this.http.get(`${environment.api}/protocol-service/list/filtered/${customerId}`, httpOptions);
  }

  getCriterias(criteriaCode?: any) {
    let params = new HttpParams();

    if (criteriaCode) {
      params = params.set('criteriaCode', criteriaCode);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params
    };

    return this.http.get(`${environment.api}/criteria-service/list/filtered`, httpOptions);
  }

  inactivateProtocol(id: any) {
    let params = new HttpParams();

    if (id) {
      params = params.set('id', id);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params
    };

    return this.http.get(`${environment.api}/protocol-service/inactivate-protocol`, httpOptions);
  }

  activateProtocol(id: any) {
    let params = new HttpParams();

    if (id) {
      params = params.set('id', id);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params
    };

    return this.http.get(`${environment.api}/protocol-service/activate-protocol`, httpOptions);
  }

  getProtocolsOnlyActive(options: { name?: any, date?: any, page?: any, size?: any }) {
    let params = new HttpParams();


    if (options.name) {
      params = params.set('name', options.name);
    }

    if (options.date) {
      params = params.set('createdAt', options.date);
    }

    if (options.page) {
      params = params.set('page', options.page);
    }

    if (options.size) {
      params = params.set('size', options.size);
    }

    params = params.set('onlyActive', true);



    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params,
    };

    return this.http.get(`${environment.api}/protocol-service/list/filtered/${this.customerId}`, httpOptions);
  }

  registerProtocol(body: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      })
    };

    return this.http.post(`${environment.api}/protocol-service/register?creationToken=${this.customerId}`, body, httpOptions);
  }

  updateProtocol(body: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      })
    };

    return this.http.put(`${environment.api}/protocol-service/update?creationToken=${this.customerId}`, body, httpOptions);
  }

  showHistory(id: any) {
    let params = new HttpParams();

    params = params.set('id', id);

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
      params
    };

    return this.http.get(`${environment.api}/protocol-service/protocol-history`, httpOptions);

  }

  public removeWhiteList(id: any, customerId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };

    let httpRequest: any = httpOptions;
    httpRequest.responseType = 'text';

    return this.http.get(`${environment.api}/protocol-service/remove-token-from-white-list/${customerId}?id=${id}`, httpRequest);
  }

  public addWhiteList(id: any, customerId: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.token,
      }),
    };

    let httpRequest: any = httpOptions;
    httpRequest.responseType = 'text';

    return this.http.get(`${environment.api}/protocol-service/add-token-to-white-list/${customerId}?id=${id}`, httpRequest);
  }

}
