<div class="select-type-content">
  <div class="header">
    <h4> {{ data.title || 'Inserir Arquivo'}} </h4>
  </div>
  <form [formGroup]="formFile" action="">
    <div class="form-container file-area">
      <div class="form-group" tabindex="-1" *ngIf="data.fileTitle">
        <input type="text" (keypress)="onKeyPress($event)" class="form-input" id="fileTitle" name="fileTitle"
          formControlName="fileTitle" />
        <label class="form-label" for="fileTitle" [ngClass]="{ focus:  formFile.get('fileTitle')?.value }">
          Nome do Arquivo
        </label>
        <span class="alert"
          *ngIf=" formFile.get('fileTitle')?.errors && (formFile.get('fileTitle')?.touched || formFile.get('fileTitle')?.dirty)">
          Campo obrigatório
        </span>
      </div>
      <input type="file" name="uploadFile" id="uploadFile" class="file" formControlName="uploadFile"
        (change)="changeFile($event)" />
    </div>
  </form>

  <div class="buttons-area">
    <button type="button" (click)="submit()">Confirmar</button>
    <button type="button" (click)="closeModal()">Cancelar</button>
  </div>
</div>