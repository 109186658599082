<!-- modal-content.component.html -->
<div class="modal-container">
    <button type="button" (click)="closeModal()" class="button-close">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.4046 8.90302C25.1985 8.10913 25.1985 6.82198 24.4046 6.02809L23.9719 5.59542C23.178 4.80153 21.8909 4.80153 21.097 5.59542L15 11.6924L8.90302 5.59542C8.10913 4.80153 6.82198 4.80153 6.02809 5.59542L5.59542 6.02809C4.80153 6.82198 4.80153 8.10913 5.59542 8.90302L11.6924 15L5.59542 21.097C4.80153 21.8909 4.80153 23.178 5.59542 23.9719L6.02809 24.4046C6.82198 25.1985 8.10913 25.1985 8.90302 24.4046L15 18.3076L21.097 24.4046C21.8909 25.1985 23.178 25.1985 23.9719 24.4046L24.4046 23.9719C25.1985 23.178 25.1985 21.8909 24.4046 21.097L18.3076 15L24.4046 8.90302Z"
                fill="#009999" />
        </svg>
    </button>
    <svg width="140" height="149" viewBox="0 0 140 149" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="70" cy="79" r="70" fill="url(#paint0_linear_0_1)" />
        <path
            d="M45.5 110.5H94.5C96.3565 110.5 98.137 109.763 99.4497 108.45C100.763 107.137 101.5 105.357 101.5 103.5V65L84 47.5H45.5C43.6435 47.5 41.863 48.2375 40.5503 49.5503C39.2375 50.863 38.5 52.6435 38.5 54.5V103.5C38.5 105.357 39.2375 107.137 40.5503 108.45C41.863 109.763 43.6435 110.5 45.5 110.5ZM52.5 54.5H66.5V61.5H73.5V54.5H80.5V68.5H52.5V54.5ZM52.5 82.5H87.5V103.5H52.5V82.5Z"
            fill="white" />
        <circle cx="116" cy="24" r="24" fill="white" />
        <path
            d="M116 0C102.728 0 92 10.728 92 24C92 37.272 102.728 48 116 48C129.272 48 140 37.272 140 24C140 10.728 129.272 0 116 0ZM126.32 34.32C126.098 34.5425 125.834 34.719 125.544 34.8394C125.254 34.9599 124.942 35.0219 124.628 35.0219C124.314 35.0219 124.002 34.9599 123.712 34.8394C123.422 34.719 123.158 34.5425 122.936 34.32L116 27.384L109.064 34.32C108.615 34.7687 108.007 35.0209 107.372 35.0209C106.737 35.0209 106.129 34.7687 105.68 34.32C105.231 33.8713 104.979 33.2626 104.979 32.628C104.979 32.3138 105.041 32.0026 105.161 31.7123C105.282 31.422 105.458 31.1582 105.68 30.936L112.616 24L105.68 17.064C105.231 16.6153 104.979 16.0066 104.979 15.372C104.979 14.7374 105.231 14.1287 105.68 13.68C106.129 13.2313 106.737 12.9792 107.372 12.9792C108.007 12.9792 108.615 13.2313 109.064 13.68L116 20.616L122.936 13.68C123.158 13.4578 123.422 13.2815 123.712 13.1613C124.003 13.041 124.314 12.9792 124.628 12.9792C124.942 12.9792 125.253 13.041 125.544 13.1613C125.834 13.2815 126.098 13.4578 126.32 13.68C126.542 13.9022 126.718 14.166 126.839 14.4563C126.959 14.7466 127.021 15.0578 127.021 15.372C127.021 15.6862 126.959 15.9974 126.839 16.2877C126.718 16.578 126.542 16.8418 126.32 17.064L119.384 24L126.32 30.936C127.232 31.848 127.232 33.384 126.32 34.32Z"
            fill="#FF3D71" />
        <defs>
            <linearGradient id="paint0_linear_0_1" x1="1.53005" y1="146.921" x2="150.433" y2="21.6813"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#001D45" />
                <stop offset="1" stop-color="#2B81AD" />
            </linearGradient>
        </defs>
    </svg>

    <div class="text-area">
        Falha no envio
    </div>
    <div class="buttons-area">
        <button class="full-button" (click)="closeModal()">Confirmar</button>
    </div>
</div>