import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {
  confirm: boolean = false;;
  error: boolean = false;
  default: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalConfirmComponent>,
  ) { }

  ngOnInit(): void {
    if (this.data.error) {
      this.default = false;
      this.error = true;
    }

    if (this.data.success) {
      this.default = false;
      this.confirm = true;
    }
  }

  submit() {
    this.dialogRef.close({ confirm: true });
  }

  closeModal() {
    this.dialogRef.close({ confirm: false });
  }

}
