import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalComponent } from './modal/modal.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SpinnerComponent } from './spinner/spinner.component';
import { MatSelectModule } from '@angular/material/select';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { ModalClassificacaoComponent } from './modal-classificacao/modal-classificacao.component';
import { ModalOverlayMapLinkComponent } from './modal-overlay-map-link/modal-overlay-map-link.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ModalFileComponent } from './modal-file/modal-file.component';
import { MatIconModule } from '@angular/material/icon';
import { CarrosselComponent } from './carrossel/carrossel.component';
import { HistoricoModalComponent } from './historico-modal/historico-modal.component';
import { TableListProtocolsComponent } from './table-list-protocols/table-list-protocols.component';
import { ModalViewCriteriasComponent } from './modal-view-criterias/modal-view-criterias.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    ModalComponent,
    SpinnerComponent,
    ModalConfirmComponent,
    ModalErrorComponent,
    ModalConfirmComponent,
    ModalClassificacaoComponent,
    ModalOverlayMapLinkComponent,
    SelectInputComponent,
    ModalFileComponent,
    CarrosselComponent,
    HistoricoModalComponent,
    TableListProtocolsComponent,
    ModalViewCriteriasComponent,
  ],
  imports: [
    MatSlideToggleModule,
    RouterModule,
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    NgxMaskModule.forRoot(maskConfig),
    MatIconModule,
  ],
  exports: [
    TableListProtocolsComponent,
    SidebarComponent,
    HeaderComponent,
    ModalComponent,
    SpinnerComponent,
    SelectInputComponent,
    CarrosselComponent,
  ]
})
export class ComponentsModule { }
