import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-classificacao',
  templateUrl: './modal-classificacao.component.html',
  styleUrls: ['./modal-classificacao.component.scss']
})
export class ModalClassificacaoComponent implements OnInit {

  formClass: FormGroup;
  class: any[] = [];
  optionsC: any;
  opened: boolean = false;

  fruits = [
    'Fruta Tropical',
    'Fruta Especial',
    'Uva',
    'Melao e Melancia',
    'Pera',
    'Fruta Citrica',
    'Maça',
    'Banana',
    'Mamão',
    'Fruta Fracionada',
    'Frutas Orgânicas'
  ]

  optionsClassification = [
    'Marca',
    'Nacional/importado',
    'categoria',
    'Sub-categoria',
    'Grupo',
    'Sub-grupo'
  ]

  brand = [
    'Exclusiva',
    'Regular'
  ]

  nationalImport = [
    'Nacional',
    'Importado'
  ]

  category = [
    'Carnes',
    'Peixaria',
    'FLV',
  ]

  subgroups = [
    "ABACATE A GRANEL",
    "ABACAXI A GRANEL",
    "ABOBORA A GRANEL",
    "ABOBRINHA A GRANEL",
    "ALCACHOFRA A GRANEL",
    "ALFACE",
    "ALHO A GRANEL",
    "AMEIXA A GRANEL",
    "AMEIXA SECA",
    "AMENDOA",
    "AMENDOIM",
    "BACTERICIDA",
    "BANANA MACA A GRANEL",
    "BANANA NANICA A GRANEL",
    "BANANA ORGANICA",
    "BANANA PASSA",
    "BANANA PRATA A GRANEL",
    "BATATA EMBALADA",
    "BATATA ESCOVADA A GRANEL",
    "BATATA LAVADA A GRANEL",
    "BATATA ORGANICA",
    "BERINJELA A GRANEL",
    "CAQUI A GRANEL",
    "CASTANHA",
    "CEBOLA COMUM A GRANEL",
    "CEBOLA EMBALADA",
    "CEBOLA ORGANICA",
    "CEBOLA ROXA A GRANEL",
    "CENOURA A GRANEL",
    "CENOURA EMBALADA",
    "CENOURA ORGANICA",
    "CEREJA A GRANEL",
    "CHUCHU A GRANEL",
    "CITRICOS ORGANICOS",
    "COCO A GRANEL",
    "COGUMELO FRESCO",
    "COGUMELO PROCESSADO",
    "COUVE FLOR E BROCOLIS",
    "DAMASCO SECO",
    "DERIVADO DE MILHO PROCESSADO",
    "ETNICO",
    "FIGO SECO",
    "FOLHAGENS E TEMPEROS",
    "FRUTA CITRICA EMBALADA",
    "FRUTA DESIDRATADA",
    "FRUTA TROPICAL EMBALADA",
    "FRUTAS EXOTICAS",
    "FRUTAS SECAS ORGANICAS",
    "GOIABA A GRANEL",
    "HORTALICAS ESPECIAIS",
    "JILO A GRANEL",
    "KIWI A GRANEL",
    "LARANJA A GRANEL",
    "LEGS A GNEL-PINHAO",
    "LEGS A GNEL-QUIABO",
    "LEGS A GNEL-VAGEM",
    "LEGUME APERITIVO EMBALADO",
    "LEGUME HIDROPONICO",
    "LIMAO A GRANEL",
    "MACA EMBALADA",
    "MACA ORGANICA",
    "MACA VERDE A GRANEL",
    "MACA VERMELHA A GRANEL",
    "MAMAO EMBALADO",
    "MAMAO FORMOSA A GRANEL",
    "MAMAO PAPAYA A GRANEL",
    "MANGA A GRANEL",
    "MARACUJA A GRANEL",
    "MAXIXE A GRANEL",
    "MELANCIA COMUM A GRANEL",
    "MELANCIAS ESPECIAIS A GRANEL",
    "MELAO AMARELO A GRANEL",
    "MELAO CANTALOUPE A GRANEL",
    "MELAO GALIA A GRANEL",
    "MELAO ORANGE A GRANEL",
    "MELAO VERDE A GRANEL",
    "MEXERICA E TANGERINA A GRANEL",
    "MILHO A GRANEL",
    "MILHO EMBALADO",
    "MILHO ORGANICO",
    "MORANGO EMBALADO",
    "MORANGO ORGANICO",
    "NECTARINA A GRANEL",
    "NOZES",
    "OUTRAS BANANAS A GRANEL",
    "OUTRAS BATATAS A GRANEL",
    "OUTRAS CEBOLAS A GRANEL",
    "OUTRAS FRUTAS A GRANEL",
    "OUTRAS FRUTAS EMBALADAS",
    "OUTRAS FRUTAS ORGANICAS",
    "OUTRAS FRUTAS SECAS",
    "OUTRAS FRUTAS TROPICAIS A GRANEL",
    "OUTRAS PERAS A GRANEL",
    "OUTROS CITRICOS A GRANEL",
    "OUTROS HIDROPONICOS",
    "OUTROS LEGUMES COMUNS",
    "OUTROS LEGUMES EMBALADOS",
    "OUTROS LEGUMES ORGANICOS",
    "OUTROS MELOES",
    "OUTROS TOMATES A GRANEL",
    "OVO BRANCO",
    "OVO CAIPIRA",
    "OVO DE CODORNA E OUTROS",
    "OVO VERMELHO",
    "OVO VITAMINADO",
    "OVOS ORGANICOS",
    "PEPINO A GRANEL",
    "PEPINO EMBALADO",
    "PEPINO ORGANICO",
    "PERA D ANJOU A GRANEL",
    "PERA EMBALADA",
    "PERA ORGANICA",
    "PERA PACKANS A GRANEL",
    "PERA PORTUGUESA A GRANEL",
    "PERA WILLIANS A GRANEL",
    "PESSEGO A GRANEL",
    "PIMENTA A GRANEL",
    "PIMENTA EMBALADA",
    "PIMENTAO A GRANEL",
    "PIMENTAO EMBALADO",
    "PIMENTAO ORGANICO",
    "PISTACHE",
    "PROCESSADOS ORGANICOS",
    "REPOLHO",
    "SUCOS NATURAIS",
    "SUCOS ORGANICOS",
    "TAMARA",
    "TEMPERO",
    "TEMPEROS EMBALADOS",
    "TEMPEROS PROCESSADOS",
    "TOMATE COMUM A GRANEL",
    "TOMATE EMBALADO",
    "TOMATE ITALIANO A GRANEL",
    "TOMATE ORGANICO",
    "TUBERCULO A GRANEL",
    "TUBERCULOS EMBALADOS",
    "UVA EMBALADA",
    "UVA ITALIA A GRANEL",
    "UVA PASSA",
    "UVA SEM SEMENTE A GRANEL",
    "UVA VERMELHA A GRANEL",
    "VERDURA",
    "VERDURA HIDROPONICA",
    "&#10148; FRUTA",
    "LEGUME PROCESSADO",
    "OUTROS HORTIFRUTIS PROCESSADOS",
    "VERDURA PROCESSADA",
    "PALMITOS FRESCOS",
    "FRUTAS CRISTALIZADAS E GLACEAD",
    "FRUTAEMCUBOS",
    "SALADADEFRUTAS",
    "UVAPRETAAGRANEL",
    "UVAROSADAAGRANEL",
    "UVARUBIAGRANEL",
    "OVOCAIPIRABEMESTARANIMAL",
    "OVOSGALINHASLIVRESGAIOLA",
    "FRUTAS FRACIONADAS INDUSTRIALI",
    "FRUTAS DESCASCADA",
    "FRUTAS SECAS A GRANEL",
    "ATP - ATMOSFERA PROTETORA",
    "BACALHAU DESFIADO DESSALGADO",
    "BACALHAU DESSALG PORTO CONGELA",
    "BACALHAU DESSALG PORTO IMPERIA",
    "BACALHAU DESSALG SAITHE CONGEL",
    "BACALHAU FILE E POSTA",
    "BACALHAU LASCAS",
    "BOVINO RESFRIADO - ATENDIMENTO",
    "CARNE DE SOL TRASEIRO",
    "CARNE ORGANICA",
    "CARNES ORGANICAS",
    "CARNES SUSTENTAVEIS",
    "COELHO / AVESTRUZ",
    "CONG - AVES SAZONAIS",
    "CONG - CORTE DE AVES SAZONAIS",
    "CONG - CORTES DE FRANGO TEMPER",
    "CONG - CORTES FRANGO COM MARCA",
    "CONG - CORTES FRANGO GENERICO",
    "CONG - CORTES SUINOS COM MARCA",
    "CONG - CORTES SUINOS GENERICOS",
    "CONG - CORTES SUINOS TEMPERADO",
    "CONG - FRANGO CAIPIRA",
    "CONG - FRANGO INTEIRO",
    "CONG - FRANGO NATURAL",
    "CONG - FRANGO ORGANICO",
    "CONG - FRANGO PRONTOS PARA CON",
    "BACALHAU PORTO CORTES",
    "BACALHAU PORTO IMPERIAL CORTES",
    "BACALHAU PORTO IMPERIAL TRADIC",
    "BACALHAU PORTO TRADICIONAL",
    "BACALHAU SAITHE TRADICIONAL",
    "CONG - ESPETINHOS",
    "CONG - FRANGO TEMPERADO FACIL",
    "CONG - LEITAO",
    "CONG - MIUDOS AVES",
    "CONG - OUTRAS AVES",
    "CONG - OVINO / CAPRINO",
    "CONG-GENERICO C/ OSSO",
    "CONG-GENERICO S/ OSSO",
    "CONG-MARCA BOVINO",
    "CONG-MIUDO BOVINO",
    "CRUSTACEO COM MARCA CONGELADO",
    "CRUSTACEO CONGELADO",
    "EMPANADO COM MARCA CONGELADO",
    "EMPANADO GENERICO CONGELADO",
    "FILE E POSTA PEIXE COM MARCA C",
    "FILE E POSTA PEIXE GENERICO CO",
    "KANI CONGELADO",
    "MOLUSCO COM MARCA CONGELADO",
    "MOLUSCO CONGELADO",
    "OUTROS PESCADOS DEFUMADOS CONG",
    "PEIXE INTEIRO CONGELADO",
    "POSTA AGUA DOCE A GRANEL",
    "POSTA AGUA SALG A GRANEL",
    "RESF - CORTES ESPECIAIS FRANGO",
    "RESF - CORTES FRANGO ATENDIMEN",
    "RESF - CORTES FRANGO COM MARCA",
    "RESF - CORTES FRANGO GENERICO",
    "RESF - CORTES FRANGO TEMPERADO",
    "RESF - CORTES SUINOS COM MARCA",
    "RESF - CORTES SUINOS ESPECIAIS",
    "RESF - CORTES SUINOS GENERICOS",
    "RESF - CORTES SUINOS TEMPERADO",
    "RESF - FRANGO CAIPIRA",
    "RESF - FRANGO INTEIRO",
    "RESF - MIUDOS AVES",
    "RESF - MIUDOS SUINOS",
    "RESF - OUTRAS AVES",
    "RESF - OVINO / CAPRINO",
    "RESF-BOVINO COM MARCA TEMPERAD",
    "RESF-BOVINO CORTE ESPECIAL",
    "RESF-BOVINO PECA",
    "RESF-BOVINO PEDACO",
    "RESF-BOVINO VACUO",
    "RESF-BOVNO BIFE",
    "RESF-CARNE BOV COM OSSO",
    "RESF-CARNE MOIDA",
    "RESF-MARCA BOVINO",
    "RESF-MARCA EXCLUSIVA BOV",
    "RESF-MIUDO BOVINO",
    "RESF-MOIDA HOMOGENEIZADA",
    "POSTAS BACALHAU DESSALGADO",
    "RESF - ESPETINHOS",
    "RUBIA GALLEGA",
    "SALMAO DEFUMADO CONGELADO",
    "SUINOS RESFRIADOS - ATENDIMENT",
    "BACALHAU LING TRADICIONAL",
    "LOMBO BACALHAU DESSALGADO",
    "OVOS GALINHAS LIVRES GAIOLA",
    "OVOS GALINHAS LIVRES GAIOLA",
    "COGUMELO SHITAKE",
    "COGUMELO SHIMEJI",
    "OVOS GALINHAS LIVRES GAIOLA",
    "COGUMELO PARIS",
    "RESF - VITELA",
    "OUTROS LEGUMES PROCESSADOS",
    "COGUMELOS ORGANICOS",
    "SUINOS SAZONAIS",
    "ALHO E CEBOLA PROCESSADA",
    "TEMPEROS FRESCOS (CULTIVAR)",
    "SWIFT - BOVINOS",
    "SWIFT - AVES",
    "SWIFT - SUINOS E OUTROS",
    "SWIFT - BOVINOS",
    "SWIFT - AVES",
    "SWIFT - SUINOS E OUTROS",
    "SWIFT - BOVINOS",
    "SWIFT - AVES",
    "SWIFT - SUINOS E OUTROS",
    "RESF - SUINOS ATP",
    "CONG - PLANT BASED",
    "RESF - PLANT BASED",
    "CARNE DE SOL DIANTEIRO",
    "OUTRAS FRUTAS TROPICAIS A GRAN",
    "OUTRAS FRUTAS TROPICAIS A GRAN",
    "OUTRAS FRUTAS TROPICAIS A GRAN",
    "UVA ROSADA A GRANEL",
    "OVO CAIPIRA BEM ESTAR ANIMAL",
    "OUTROS COGUMELOS",
    "TAPIOCAS",
    "SEMI PRONTOS (VAPZA)",
    "ALHO EMBALADO"
  ];

  groups: string[] = [
    'LEGUMES ORGANICOS',
    'BATATA ORGANICA',
    'CEBOLA ORGANICA',
    'CENOURA ORGANICA',
    'MILHO ORGANICO',
    'OUTROS LEGUMES ORGANICOS',
    'PEPINO ORGANICO',
    'PIMENTAO ORGANICO',
    'TOMATE ORGANICO',
    'FOLHAS OVOS E SUCOS ORGANICOS',
    'FOLHAGENS E TEMPEROS',
    'FRUTAS SECAS ORGANICAS',
    'OVOS ORGANICOS',
    'PROCESSADOS ORGANICOS',
    'SUCOS ORGANICOS',
    'AVES-CONGELADAS',
    'CONG - AVES SAZONAIS',
    'CONG - CORTE DE AVES SAZONAIS',
    'CONG - CORTES DE FRANGO TEMPER',
    'CONG - CORTES FRANGO COM MARCA',
    'CONG - CORTES FRANGO GENERICO',
    'CONG - FRANGO CAIPIRA',
    'CONG - FRANGO INTEIRO',
    'CONG - FRANGO NATURAL',
    'CONG - FRANGO ORGANICO',
    'CONG - FRANGO PRONTOS PARA CON',
    'CONG - FRANGO TEMPERADO FACIL',
    'CONG - MIUDOS AVES',
    'CONG - OUTRAS AVES',
    'BOV-CONGELADOS',
    'CONG-GENERICO C/ OSSO',
    'CONG-GENERICO S/ OSSO',
    'CONG-MARCA BOVINO',
    'CONG-MIUDO BOVINO',
    'SWIFT - BOVINOS',
    'SWIFT - AVES',
    'SWIFT - SUINOS E OUTROS',
    'SWIFT - BOVINOS',
    'SWIFT - AVES',
    'SWIFT - SUINOS E OUTROS',
    'SWIFT - BOVINOS',
    'SWIFT - AVES',
    'SWIFT - SUINOS E OUTROS',
    'BOV-RESFRIADOS',
    'ATP - ATMOSFERA PROTETORA',
    'BOVINO RESFRIADO - ATENDIMENTO',
    'CARNE ORGANICA',
    'RESF-BOVINO COM MARCA TEMPERAD',
    'RESF-BOVINO CORTE ESPECIAL',
    'RESF-BOVINO PECA',
    'RESF-BOVINO PEDACO',
    'RESF-BOVINO VACUO',
    'RESF-BOVNO BIFE',
    'RESF-CARNE BOV COM OSSO',
    'RESF-CARNE MOIDA',
    'RESF-MARCA BOVINO',
    'RESF-MARCA EXCLUSIVA BOV',
    'RESF-MIUDO BOVINO',
    'RESF-MOIDA HOMOGENEIZADA',
    'RUBIA GALLEGA',
    'CARNES DIFERENCIADAS',
    'COELHO / AVESTRUZ',
    'CONG - OVINO / CAPRINO',
    'RESF - OVINO / CAPRINO',
    'SUINOS-CONGELADOS',
    'CONG - CORTES SUINOS COM MARCA',
    'CONG - CORTES SUINOS GENERICOS',
    'CONG - CORTES SUINOS TEMPERADO',
    'CONG - LEITAO',
    'SUINOS-RESFRIADOS',
    'RESF - CORTES SUINOS COM MARCA',
    'RESF - CORTES SUINOS ESPECIAIS',
    'RESF - CORTES SUINOS GENERICOS',
    'RESF - CORTES SUINOS TEMPERADO',
    'RESF - MIUDOS SUINOS',
    'SUINOS RESFRIADOS - ATENDIMENT',
    'BACALHAU CORTE ELABORADO',
    'BACALHAU FILE E POSTA',
    'BACALHAU LASCAS',
    'BACALHAU LING',
    'BACALHAU LING TRADICIONAL',
    'BACALHAU PORTO',
    'BACALHAU PORTO CORTES',
    'BACALHAU PORTO TRADICIONAL',
    'BACALHAU PORTO IMPERIAL',
    'BACALHAU PORTO IMPERIAL CORTES',
    'BACALHAU PORTO IMPERIAL TRADIC',
    'BACALHAU DESSALGADO',
    'BACALHAU DESFIADO DESSALGADO',
    'BACALHAU DESSALG PORTO CONGELA',
    'BACALHAU DESSALG PORTO IMPERIA',
    'BACALHAU DESSALG SAITHE CONGEL',
    'POSTAS BACALHAU DESSALGADO',
    'LOMBO BACALHAU DESSALGADO',
    'FRUTO DO MAR CONGELADO',
    'CRUSTACEO COM MARCA CONGELADO',
    'CRUSTACEO CONGELADO',
    'KANI CONGELADO',
    'MOLUSCO COM MARCA CONGELADO',
    'MOLUSCO CONGELADO',
    'EMPANADO DE PEIXE CONGELADO',
    'EMPANADO COM MARCA CONGELADO',
    'EMPANADO GENERICO CONGELADO',
    'PEIXE CONGELADO',
    'FILE E POSTA PEIXE COM MARCA C',
    'FILE E POSTA PEIXE GENERICO CO',
    'PEIXE INTEIRO CONGELADO',
    'POSTA AGUA DOCE A GRANEL',
    'POSTA AGUA SALG A GRANEL',
    'PEIXE DEFUMADO',
    'OUTROS PESCADOS DEFUMADOS CONG',
    'SALMAO DEFUMADO CONGELADO',
    'FRUTA TROPICAL',
    'ABACATE A GRANEL',
    'ABACAXI A GRANEL',
    'COCO A GRANEL',
    'FRUTA TROPICAL EMBALADA',
    'GOIABA A GRANEL',
    'MANGA A GRANEL',
    'OUTRAS FRUTAS TROPICAIS A GRANEL',
    'OUTRAS FRUTAS TROPICAIS A GRAN',
    'OUTRAS FRUTAS TROPICAIS A GRAN',
    'OUTRAS FRUTAS TROPICAIS A GRAN',
    'FRUTA ESPECIAL',
    'AMEIXA A GRANEL',
    'CAQUI A GRANEL',
    'CEREJA A GRANEL',
    'FRUTAS EXOTICAS',
    'KIWI A GRANEL',
    'MORANGO EMBALADO',
    'NECTARINA A GRANEL',
    'OUTRAS FRUTAS A GRANEL',
    'OUTRAS FRUTAS EMBALADAS',
    'PESSEGO A GRANEL',
    'UVA',
    'UVA EMBALADA',
    'UVA ITALIA A GRANEL',
    'UVA SEM SEMENTE A GRANEL',
    'UVA VERMELHA A GRANEL',
    'UVAPRETAAGRANEL',
    'UVAROSADAAGRANEL',
    'UVARUBIAGRANEL',
    'UVA ROSADA A GRANEL',
    'MELAO E MELANCIA',
    'MELANCIA COMUM A GRANEL',
    'MELANCIAS ESPECIAIS A GRANEL',
    'MELAO AMARELO A GRANEL',
    'MELAO CANTALOUPE A GRANEL',
    'MELAO GALIA A GRANEL',
    'MELAO ORANGE A GRANEL',
    'MELAO VERDE A GRANEL',
    'OUTROS MELOES',
    'PERA',
    'OUTRAS PERAS A GRANEL',
    'PERA D ANJOU A GRANEL',
    'PERA EMBALADA',
    'PERA PACKANS A GRANEL',
    'PERA PORTUGUESA A GRANEL',
    'PERA WILLIANS A GRANEL',
    'FRUTA CITRICA',
    'FRUTA CITRICA EMBALADA',
    'LARANJA A GRANEL',
    'LIMAO A GRANEL',
    'MARACUJA A GRANEL',
    'MEXERICA E TANGERINA A GRANEL',
    'OUTROS CITRICOS A GRANEL',
    'MACA',
    'MACA EMBALADA',
    'MACA VERDE A GRANEL',
    'MACA VERMELHA A GRANEL',
    'BANANA',
    'BANANA MACA A GRANEL',
    'BANANA NANICA A GRANEL',
    'BANANA PRATA A GRANEL',
    'OUTRAS BANANAS A GRANEL',
    'MAMAO',
    'MAMAO EMBALADO',
    'MAMAO FORMOSA A GRANEL',
    'MAMAO PAPAYA A GRANEL',
    'FRUTA FRACIONADA',
    'SUCOS NATURAIS',
    'FRUTAEMCUBOS',
    'SALADADEFRUTAS',
    'FRUTAS DESCASCADA',
    'CENOURA A GRANEL',
    'CENOURA A GRANEL',
    'ALHO A GRANEL',
    'ALHO A GRANEL',
    'LEGUME EMBALADO',
    'BATATA EMBALADA',
    'CEBOLA EMBALADA',
    'CENOURA EMBALADA',
    'LEGUME APERITIVO EMBALADO',
    'MILHO EMBALADO',
    'OUTROS LEGUMES EMBALADOS',
    'PEPINO EMBALADO',
    'PIMENTA EMBALADA',
    'PIMENTAO EMBALADO',
    'TOMATE EMBALADO',
    'TUBERCULOS EMBALADOS',
    'LEGUME COMUM A GRANEL',
    'ABOBORA A GRANEL',
    'ABOBRINHA A GRANEL',
    'ALCACHOFRA A GRANEL',
    'BERINJELA A GRANEL',
    'CHUCHU A GRANEL',
    'JILO A GRANEL',
    'LEGS A GNEL-PINHAO',
    'LEGS A GNEL-QUIABO',
    'LEGS A GNEL-VAGEM',
    'MAXIXE A GRANEL',
    'MILHO A GRANEL',
    'OUTROS LEGUMES COMUNS',
    'PEPINO A GRANEL',
    'PIMENTA A GRANEL',
    'PIMENTAO A GRANEL',
    'TUBERCULO A GRANEL',
    'CEBOLA A GRANEL',
    'CEBOLA COMUM A GRANEL',
    'CEBOLA ROXA A GRANEL',
    'OUTRAS CEBOLAS A GRANEL',
    'BATATA A GRANEL',
    'BATATA ESCOVADA A GRANEL',
    'BATATA LAVADA A GRANEL',
    'OUTRAS BATATAS A GRANEL',
    'TOMATE A GRANEL',
    'OUTROS TOMATES A GRANEL',
    'TOMATE COMUM A GRANEL',
    'TOMATE ITALIANO A GRANEL',
    'OVOBEM-ESTARANIMAL',
    'OVOCAIPIRABEMESTARANIMAL',
    'OVOSGALINHASLIVRESGAIOLA',
    'VERDURA E LEGUME HIDROPONICO',
    'LEGUME HIDROPONICO',
    'OUTROS HIDROPONICOS',
    'VERDURA HIDROPONICA',
    'FRUTA SECA',
    'AMEIXA SECA',
    'AMENDOA',
    'AMENDOIM',
    'BANANA PASSA',
    'CASTANHA',
    'DAMASCO SECO',
    'FIGO SECO',
    'FRUTA DESIDRATADA',
    'NOZES',
    'OUTRAS FRUTAS SECAS',
    'PISTACHE',
    'TAMARA',
    'UVA PASSA',
    'FRUTAS CRISTALIZADAS E GLACEAD',
    'FRUTAS SECAS A GRANEL',
    'PROCESSADOS',
    'BACTERICIDA',
    'DERIVADO DE MILHO PROCESSADO',
    'ETNICO',
    'FRUTA',
    'LEGUME PROCESSADO',
    'OUTROS HORTIFRUTIS PROCESSADOS',
    'VERDURA PROCESSADA',
    'COGUMELO',
    'COGUMELO FRESCO',
    'COGUMELO PROCESSADO',
    'VERDURA E HORTALICA CONVENCION',
    'ALFACE',
    'COUVE FLOR E BROCOLIS',
    'HORTALICAS ESPECIAIS',
    'REPOLHO',
    'TEMPERO',
    'VERDURA',
    'OVO COMUM',
    'OVO BRANCO',
    'OVO VERMELHO',
    'OVO ESPECIAL',
    'OVO CAIPIRA',
    'OVO DE CODORNA E OUTROS',
    'OVO VITAMINADO',
    'TEMPERO PROCESSADO EMBALADO',
    'TEMPEROS EMBALADOS',
    'TEMPEROS PROCESSADOS',
    'AVES-RESFRIADAS',
    'RESF - CORTES ESPECIAIS FRANGO',
    'RESF - CORTES FRANGO ATENDIMEN',
    'RESF - CORTES FRANGO COM MARCA',
    'RESF - CORTES FRANGO GENERICO',
    'RESF - CORTES FRANGO TEMPERADO',
    'RESF - FRANGO CAIPIRA',
    'RESF - FRANGO INTEIRO',
    'RESF - MIUDOS AVES',
    'RESF - OUTRAS AVES',
    'CARNES ORGANICAS E SUSTENTAVEI',
    'CARNES ORGANICAS',
    'CARNES SUSTENTAVEIS',
    'SALG E DEF-SALG BOV',
    'CARNE DE SOL TRASEIRO',
    'CARNE DE SOL DIANTEIRO',
    'ESPETINHOS',
    'CONG - ESPETINHOS',
    'RESF - ESPETINHOS',
    'BACALHAU SAITHE',
    'BACALHAU SAITHE TRADICIONAL',
    'FRUTAS ORGANICAS',
    'BANANA ORGANICA',
    'CITRICOS ORGANICOS',
    'MACA ORGANICA',
    'MORANGO ORGANICO',
    'OUTRAS FRUTAS ORGANICAS',
    'PERA ORGANICA',
    'SUINOS ATP',
    'PLANT BASED',
    'CONG - PLANT BASED',
    'RESF - PLANT BASED',
    'OVO BEM-ESTAR ANIMAL',
    'OVOS GALINHAS LIVRES GAIOLA',
    'OVOS GALINHAS LIVRES GAIOLA',
    'OVOS GALINHAS LIVRES GAIOLA',
    'OVO CAIPIRA BEM ESTAR ANIMAL',
  ];



  subcategory = [
    'OVOS',
    'PROCESSADOS',
    'FRUTAS SECAS',
    'FRUTAS',
    'FRUTA TROPICAL',
    'ABACATE A GRANEL',
    'ABACAXI A GRANEL',
    'COCO A GRANEL',
    'FRUTA TROPICAL EMBALADA',
    'GOIABA A GRANEL',
    'MANGA A GRANEL',
    'OUTRAS FRUTAS TROPICAIS A GRANEL',
    'OUTRAS FRUTAS TROPICAIS A GRAN',
    'OUTRAS FRUTAS TROPICAIS A GRAN',
    'OUTRAS FRUTAS TROPICAIS A GRAN',
    'OUTRAS FRUTAS TROPICAIS A GRAN',
    'FRUTA ESPECIAL',
    'AMEIXA A GRANEL',
    'CAQUI A GRANEL',
    'CEREJA A GRANEL',
    'FRUTAS EXOTICAS',
    'KIWI A GRANEL',
    'MORANGO EMBALADO',
    'NECTARINA A GRANEL',
    'OUTRAS FRUTAS A GRANEL',
    'OUTRAS FRUTAS EMBALADAS',
    'PESSEGO A GRANEL',
    'UVA',
    'UVA EMBALADA',
    'UVA ITALIA A GRANEL',
    'UVA SEM SEMENTE A GRANEL',
    'UVA VERMELHA A GRANEL',
    'UVAPRETAAGRANEL',
    'UVAROSADAAGRANEL',
    'UVARUBIAGRANEL',
    'UVA ROSADA A GRANEL',
    'MELAO E MELANCIA',
    'MELANCIA COMUM A GRANEL',
    'MELANCIAS ESPECIAIS A GRANEL',
    'MELAO AMARELO A GRANEL',
    'MELAO CANTALOUPE A GRANEL',
    'MELAO GALIA A GRANEL',
    'MELAO ORANGE A GRANEL',
    'MELAO VERDE A GRANEL',
    'OUTROS MELOES',
    'PERA',
    'OUTRAS PERAS A GRANEL',
    'PERA D ANJOU A GRANEL',
    'PERA EMBALADA',
    'PERA PACKANS A GRANEL',
    'PERA PORTUGUESA A GRANEL',
    'PERA WILLIANS A GRANEL',
    'FRUTA CITRICA',
    'FRUTA CITRICA EMBALADA',
    'LARANJA A GRANEL',
    'LIMAO A GRANEL',
    'MARACUJA A GRANEL',
    'MEXERICA E TANGERINA A GRANEL',
    'OUTROS CITRICOS A GRANEL',
    'MACA',
    'MACA EMBALADA',
    'MACA VERDE A GRANEL',
    'MACA VERMELHA A GRANEL',
    'BANANA',
    'BANANA MACA A GRANEL',
    'BANANA NANICA A GRANEL',
    'BANANA PRATA A GRANEL',
    'OUTRAS BANANAS A GRANEL',
    'MAMAO',
    'MAMAO EMBALADO',
    'MAMAO FORMOSA A GRANEL',
    'MAMAO PAPAYA A GRANEL',
    'FRUTA FRACIONADA',
    'SUCOS NATURAIS',
    'FRUTAEMCUBOS',
    'SALADADEFRUTAS',
    'FRUTAS DESCASCADA',
    'FRUTAS ORGANICAS',
    'BANANA ORGANICA',
    'CITRICOS ORGANICOS',
    'MACA ORGANICA',
    'MORANGO ORGANICO',
    'OUTRAS FRUTAS ORGANICAS',
    'PERA ORGANICA',
    'AVES',
    'AVES-CONGELADAS',
    'CONG - AVES SAZONAIS',
    'CONG - CORTE DE AVES SAZONAIS',
    'CONG - CORTES DE FRANGO TEMPER',
    'CONG - CORTES FRANGO COM MARCA',
    'CONG - CORTES FRANGO GENERICO',
    'CONG - FRANGO CAIPIRA',
    'CONG - FRANGO INTEIRO',
    'CONG - FRANGO NATURAL',
    'CONG - FRANGO ORGANICO',
    'CONG - FRANGO PRONTOS PARA CON',
    'CONG - FRANGO TEMPERADO FACIL',
    'CONG - MIUDOS AVES',
    'CONG - OUTRAS AVES',
    'AVES-RESFRIADAS',
    'RESF - CORTES ESPECIAIS FRANGO',
    'RESF - CORTES FRANGO ATENDIMEN',
    'RESF - CORTES FRANGO COM MARCA',
    'RESF - CORTES FRANGO GENERICO',
    'RESF - CORTES FRANGO TEMPERADO',
    'RESF - FRANGO CAIPIRA',
    'RESF - FRANGO INTEIRO',
    'RESF - MIUDOS AVES',
    'RESF - OUTRAS AVES',
    'SUINOS E OUTROS',
    'CARNES DIFERENCIADAS',
    'COELHO / AVESTRUZ',
    'CONG - OVINO / CAPRINO',
    'RESF - OVINO / CAPRINO',
    'SUINOS-CONGELADOS',
    'CONG - CORTES SUINOS COM MARCA',
    'CONG - CORTES SUINOS GENERICOS',
    'CONG - CORTES SUINOS TEMPERADO',
    'CONG - LEITAO',
    'SUINOS-RESFRIADOS',
    'RESF - CORTES SUINOS COM MARCA',
    'RESF - CORTES SUINOS ESPECIAIS',
    'RESF - CORTES SUINOS GENERICOS',
    'RESF - CORTES SUINOS TEMPERADO',
    'RESF - MIUDOS SUINOS',
    'SUINOS RESFRIADOS - ATENDIMENT',
    'ESPETINHOS',
    'CONG - ESPETINHOS',
    'RESF - ESPETINHOS',
    'SUINOS ATP',
    'PEIXES CONGELADOS',
    'BACALHAU CORTE ELABORADO',
    'BACALHAU FILE E POSTA',
    'BACALHAU LASCAS',
    'BACALHAU LING',
    'BACALHAU LING TRADICIONAL',
    'BACALHAU PORTO',
    'BACALHAU PORTO CORTES',
    'BACALHAU PORTO TRADICIONAL',
    'BACALHAU PORTO IMPERIAL',
    'BACALHAU PORTO IMPERIAL CORTES',
    'BACALHAU PORTO IMPERIAL TRADIC',
    'BACALHAU SAITHE',
    'BACALHAU SAITHE TRADICIONAL',
    'BACALHAU',
    'BACALHAU DESSALGADO',
    'BACALHAU DESFIADO DESSALGADO',
    'BACALHAU DESSALG PORTO CONGELA',
    'BACALHAU DESSALG PORTO IMPERIA',
    'BACALHAU DESSALG SAITHE CONGEL',
    'POSTAS BACALHAU DESSALGADO',
    'LOMBO BACALHAU DESSALGADO',
    'FRUTO DO MAR CONGELADO',
    'CRUSTACEO COM MARCA CONGELADO',
    'CRUSTACEO CONGELADO',
    'KANI CONGELADO',
    'MOLUSCO COM MARCA CONGELADO',
    'MOLUSCO CONGELADO',
    'EMPANADO DE PEIXE CONGELADO',
    'EMPANADO COM MARCA CONGELADO',
    'EMPANADO GENERICO CONGELADO',
    'PEIXE CONGELADO',
    'FILE E POSTA PEIXE COM MARCA C',
    'FILE E POSTA PEIXE GENERICO CO',
    'PEIXE INTEIRO CONGELADO',
    'POSTA AGUA DOCE A GRANEL',
    'POSTA AGUA SALG A GRANEL',
    'PEIXE DEFUMADO',
    'OUTROS PESCADOS DEFUMADOS CONG',
    'SALMAO DEFUMADO CONGELADO',
    'LEGUMES',
    'LEGUMES ORGANICOS',
    'BATATA ORGANICA',
    'CEBOLA ORGANICA',
    'CENOURA ORGANICA',
    'MILHO ORGANICO',
    'OUTROS LEGUMES ORGANICOS',
    'PEPINO ORGANICO',
    'PIMENTAO ORGANICO',
    'TOMATE ORGANICO',
    'CENOURA A GRANEL',
    'CENOURA A GRANEL',
    'ALHO A GRANEL',
    'ALHO A GRANEL',
    'LEGUME EMBALADO',
    'BATATA EMBALADA',
    'CEBOLA EMBALADA',
    'CENOURA EMBALADA',
    'LEGUME APERITIVO EMBALADO',
    'MILHO EMBALADO',
    'OUTROS LEGUMES EMBALADOS',
    'PEPINO EMBALADO',
    'PIMENTA EMBALADA',
    'PIMENTAO EMBALADO',
    'TOMATE EMBALADO',
    'TUBERCULOS EMBALADOS',
    'LEGUME COMUM A GRANEL',
    'ABOBORA A GRANEL',
    'ABOBRINHA A GRANEL',
    'ALCACHOFRA A GRANEL',
    'BERINJELA A GRANEL',
    'CHUCHU A GRANEL',
    'JILO A GRANEL',
    'LEGS A GNEL-PINHAO',
    'LEGS A GNEL-QUIABO',
    'LEGS A GNEL-VAGEM',
    'MAXIXE A GRANEL',
    'MILHO A GRANEL',
    'OUTROS LEGUMES COMUNS',
    'PEPINO A GRANEL',
    'PIMENTA A GRANEL',
    'PIMENTAO A GRANEL',
    'TUBERCULO A GRANEL',
    'CEBOLA A GRANEL',
    'CEBOLA COMUM A GRANEL',
    'CEBOLA ROXA A GRANEL',
    'OUTRAS CEBOLAS A GRANEL',
    'BATATA A GRANEL',
    'BATATA ESCOVADA A GRANEL',
    'BATATA LAVADA A GRANEL',
    'OUTRAS BATATAS A GRANEL',
    'TOMATE A GRANEL',
    'OUTROS TOMATES A GRANEL',
    'TOMATE COMUM A GRANEL',
    'TOMATE ITALIANO A GRANEL',
    'FOLHAGENS E PROCESS',
    'FOLHAS OVOS E SUCOS ORGANICOS',
    'FOLHAGENS E TEMPEROS',
    'FRUTAS SECAS ORGANICAS',
    'OVOS ORGANICOS',
    'PROCESSADOS ORGANICOS',
    'SUCOS ORGANICOS',
    'OVOBEM-ESTARANIMAL',
    'OVOCAIPIRABEMESTARANIMAL',
    'OVOSGALINHASLIVRESGAIOLA',
    'VERDURA E LEGUME HIDROPONICO',
    'LEGUME HIDROPONICO',
    'OUTROS HIDROPONICOS',
    'VERDURA HIDROPONICA',
    'FRUTA SECA',
    'AMEIXA SECA',
    'AMENDOA',
    'AMENDOIM',
    'BANANA PASSA',
    'CASTANHA',
    'DAMASCO SECO',
    'FIGO SECO',
    'FRUTA DESIDRATADA',
    'NOZES',
    'OUTRAS FRUTAS SECAS',
    'PISTACHE',
    'TAMARA',
    'UVA PASSA',
    'FRUTAS CRISTALIZADAS E GLACEAD',
    'FRUTAS SECAS A GRANEL',
    'PROCESSADOS',
    'BACTERICIDA',
    'DERIVADO DE MILHO PROCESSADO',
    'ETNICO',
    'FRUTA',
    'LEGUME PROCESSADO',
    'OUTROS HORTIFRUTIS PROCESSADOS',
    'VERDURA PROCESSADA',
    'COGUMELO',
    'COGUMELO FRESCO',
    'COGUMELO PROCESSADO',
    'VERDURA E HORTALICA CONVENCION',
    'ALFACE',
    'COUVE FLOR E BROCOLIS',
    'HORTALICAS ESPECIAIS',
    'REPOLHO',
    'TEMPERO',
    'VERDURA',
    'OVO COMUM',
    'OVO BRANCO',
    'OVO VERMELHO',
    'OVO ESPECIAL',
    'OVO CAIPIRA',
    'OVO DE CODORNA E OUTROS',
    'OVO VITAMINADO',
    'TEMPERO PROCESSADO EMBALADO',
    'TEMPEROS EMBALADOS',
    'TEMPEROS PROCESSADOS',
    'OVO BEM-ESTAR ANIMAL',
    'OVOS GALINHAS LIVRES GAIOLA',
    'OVOS GALINHAS LIVRES GAIOLA',
    'OVOS GALINHAS LIVRES GAIOLA',
    'OVO CAIPIRA BEM ESTAR ANIMAL',
    'BOVINOS',
    'BOV-CONGELADOS',
    'CONG-GENERICO C/ OSSO',
    'CONG-GENERICO S/ OSSO',
    'CONG-MARCA BOVINO',
    'CONG-MIUDO BOVINO',
    'SWIFT - BOVINOS',
    'SWIFT - AVES',
    'SWIFT - SUINOS E OUTROS',
    'SWIFT - BOVINOS',
    'SWIFT - AVES',
    'SWIFT - SUINOS E OUTROS',
    'SWIFT - BOVINOS',
    'SWIFT - AVES',
    'SWIFT - SUINOS E OUTROS',
    'BOV-RESFRIADOS',
    'ATP - ATMOSFERA PROTETORA',
    'BOVINO RESFRIADO - ATENDIMENTO',
    'CARNE ORGANICA',
    'RESF-BOVINO COM MARCA TEMPERAD',
    'RESF-BOVINO CORTE ESPECIAL',
    'RESF-BOVINO PECA',
    'RESF-BOVINO PEDACO',
    'RESF-BOVINO VACUO',
    'RESF-BOVNO BIFE',
    'RESF-CARNE BOV COM OSSO',
    'RESF-CARNE MOIDA',
    'RESF-MARCA BOVINO',
    'RESF-MARCA EXCLUSIVA BOV',
    'RESF-MIUDO BOVINO',
    'RESF-MOIDA HOMOGENEIZADA',
    'RUBIA GALLEGA',
    'CARNES ORGANICAS E SUSTENTAVEI',
    'CARNES ORGANICAS',
    'CARNES SUSTENTAVEIS',
    'SALG E DEF-SALG BOV',
    'CARNE DE SOL TRASEIRO',
    'CARNE DE SOL DIANTEIRO',
    'PLANT BASED',
    'CONG - PLANT BASED',
    'RESF - PLANT BASED',
  ];

  tropicalFruits = [
    'ABACATE A GRANEL',
    'ABACAXI A GRANEL',
    'COCO A GRANEL',
    'FRUTA TROPICAL EMBALADA',
    'GOIABA A GRANEL',
    'MANGA A GRANEL'
  ];

  especialFruits = [
    'AMEIXA A GRANEL',
    'CAQUI A GRANEL',
    'CEREJA A GRANEL',
    'FRUTAS EXOTICAS',
    'KIWI A GRANEL',
    'MORANGO EMBALADO',
    'NECTARINA A GRANEL',
    'OUTRAS FRUTAS A GRANEL',
    'OUTRAS FRUTAS EMBALADAS',
    'PESSEGO A GRANEL'
  ];

  uvas = [
    'UVA EMBALADA',
    'UVA ITALIA A GRANEL',
    'UVA SEM SEMENTE A GRANEL',
    'UVA VERMELHA A GRANEL',
    'UVAPRETAAGRANEL',
    'UVAROSADAAGRANEL',
    'UVARUBIAGRANEL',
    'UVA ROSADA A GRANEL'
  ];

  meloes = [
    'MELANCIA COMUM A GRANEL',
    'MELANCIAS ESPECIAIS A GRANEL',
    'MELAO AMARELO A GRANEL',
    'MELAO CANTALOUPE A GRANEL',
    'MELAO GALIA A GRANEL',
    'MELAO ORANGE A GRANEL',
    'MELAO VERDE A GRANEL',
    'OUTROS MELOES'
  ];

  listaDeMeloes = [
    'MELANCIA COMUM A GRANEL',
    'MELANCIAS ESPECIAIS A GRANEL',
    'MELAO AMARELO A GRANEL',
    'MELAO CANTALOUPE A GRANEL',
    'MELAO GALIA A GRANEL',
    'MELAO ORANGE A GRANEL',
    'MELAO VERDE A GRANEL',
    'OUTROS MELOES'
  ];

  peras = [
    'OUTRAS PERAS A GRANEL',
    'PERA D ANJOU A GRANEL',
    'PERA EMBALADA',
    'PERA PACKANS A GRANEL',
    'PERA PORTUGUESA A GRANEL',
    'PERA WILLIAMS A GRANEL'
  ];

  citricFruit = [
    'FRUTA CITRICA EMBALADA',
    'LARANJA A GRANEL',
    'LIMAO A GRANEL',
    'MARACUJA A GRANEL',
    'MEXERICA E TANGERINA A GRANEL',
    'OUTROS CITRICOS A GRANEL'
  ]

  macas = [
    'MACA EMBALADA',
    'MACA VERDE A GRANEL',
    'MACA VERMELHA A GRANEL'
  ];

  bananas = [
    'BANANA MACA A GRANEL',
    'BANANA NANICA A GRANEL',
    'BANANA PRATA A GRANEL',
    'OUTRAS BANANAS A GRANEL'
  ];

  mamaos = [
    'MAMAO EMBALADO',
    'MAMAO FORMOSA A GRANEL',
    'MAMAO PAPAYA A GRANEL'
  ];

  fruitsFractional = [
    'SUCOS NATURAIS',
    'FRUTAEMCUBOS',
    'SALADADEFRUTAS',
    'FRUTAS DESCASCADA'
  ];

  organicFruits = [
    'BANANA ORGANICA',
    'CITRICOS ORGANICOS',
    'MACA ORGANICA',
    'MORANGO ORGANICO',
    'OUTRAS FRUTAS ORGANICAS',
    'PERA ORGANICA'
  ]

  constructor(public dialogRef: MatDialogRef<ModalClassificacaoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.formClass = new FormGroup({
      class: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    if (this.data.name) {
      this.setDatas();
    }
  }

  setDatas() {
    this.class = this.data.class;
    this.formClass.get('class')?.setValue(this.data.name);
  }

  cleanClass() {
    this.class = [];
  }

  addClass(classe: any): void {
    const index = this.class.indexOf(classe);

    if (index === -1) {
      this.class.push(classe);
    } else {
      this.class.splice(index, 1);
    }
  }

  isChecked(item: string): boolean {
    return this.data ? this.data.class.includes(item) : false;
  }

  confirmModal() {
    const data = {
      name: this.formClass.get("class")?.value,
      class: this.class
    }

    // Feche o modal e passe os dados
    this.dialogRef.close(data);
  }

  verifyOption(op: any) {
    let opcao = op.target.value;

    if (opcao == 'Sub-categoria') {

      //this.getCheckBoxProduct()
    }
  }

  open(item: any) {
    this.opened = true;
  }

  closeModal() {
    let data = null;
    this.dialogRef.close(data);
  }
}
