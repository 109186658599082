import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private storage!: Storage;
  public user: any;

  constructor(private authService:AuthService, private router: Router ) {
    this.storage = window.sessionStorage
  }

  ngOnInit(): void {
    const item = this.storage.getItem('user');
    this.user = item ? JSON.parse(item) : {};
  }

  getAuthoritiesName(): string {
    let authorite = 'Cliente';
    this.user.authorities.forEach((item: string) => {
      switch (item) {
        case 'ROLE_ADMIN':
          authorite = 'Admin';
          break;
        case 'ROLE_GEOMONITORING_ADMIN':
          authorite = 'Admin';
          break;
        default:
          authorite = 'Cliente';
      }
    });
    return authorite;
  }

  logout(){
    this.authService.logout()
  }
}
