import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private storage: Storage;

  constructor(private router: Router, private authService: AuthService) {
    this.storage = window.localStorage;
  }

  get(key: string): any {
    if (this.storage) {
      let obj = this.storage.getItem(key);
      return JSON.parse(obj!);
    }
    return null;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.auth()) {
      return true;
    }
    this.router.navigateByUrl('login/safe-gis');
    return false;
  }
}
