<ng-container *ngIf="show" [ngSwitch]="typeModal">
  <ng-container *ngSwitchCase="'modalSave'">
    <div class="modal" @modal [ngSwitch]="typeContent">
      <span class="close" (click)="toggle()">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.4046 3.90302C20.1985 3.10913 20.1985 1.82198 19.4046 1.02809L18.9719 0.595418C18.178 -0.198473 16.8909 -0.198472 16.097 0.595418L10 6.6924L3.90302 0.59542C3.10913 -0.19847 1.82198 -0.198472 1.02809 0.595419L0.595418 1.02809C-0.198473 1.82198 -0.198472 3.10913 0.595418 3.90302L6.6924 10L0.59542 16.097C-0.198471 16.8909 -0.198473 18.178 0.595418 18.9719L1.02809 19.4046C1.82198 20.1985 3.10913 20.1985 3.90302 19.4046L10 13.3076L16.097 19.4046C16.8909 20.1985 18.178 20.1985 18.9719 19.4046L19.4046 18.9719C20.1985 18.178 20.1985 16.8909 19.4046 16.097L13.3076 10L19.4046 3.90302Z"
            fill="url(#paint0_linear_836_15426)" />
          <defs>
            <linearGradient id="paint0_linear_836_15426" x1="0.218579" y1="19.703" x2="21.4904" y2="1.81162"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#001D45" />
              <stop offset="1" stop-color="#2B81AD" />
            </linearGradient>
          </defs>
        </svg>
      </span>

      <div class="header">
        <div class="error" *ngSwitchCase="'error'">
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M24 0C10.728 0 0 10.728 0 24C0 37.272 10.728 48 24 48C37.272 48 48 37.272 48 24C48 10.728 37.272 0 24 0ZM34.32 34.32C34.098 34.5425 33.8342 34.719 33.5439 34.8394C33.2536 34.9599 32.9423 35.0219 32.628 35.0219C32.3137 35.0219 32.0024 34.9599 31.7121 34.8394C31.4218 34.719 31.158 34.5425 30.936 34.32L24 27.384L17.064 34.32C16.6153 34.7687 16.0066 35.0209 15.372 35.0209C14.7374 35.0209 14.1287 34.7687 13.68 34.32C13.2313 33.8713 12.9792 33.2626 12.9792 32.628C12.9792 32.3138 13.041 32.0026 13.1613 31.7123C13.2815 31.422 13.4578 31.1582 13.68 30.936L20.616 24L13.68 17.064C13.2313 16.6153 12.9792 16.0066 12.9792 15.372C12.9792 14.7374 13.2313 14.1287 13.68 13.68C14.1287 13.2313 14.7374 12.9792 15.372 12.9792C16.0066 12.9792 16.6153 13.2313 17.064 13.68L24 20.616L30.936 13.68C31.1582 13.4578 31.422 13.2815 31.7123 13.1613C32.0026 13.041 32.3138 12.9792 32.628 12.9792C32.9422 12.9792 33.2534 13.041 33.5437 13.1613C33.834 13.2815 34.0978 13.4578 34.32 13.68C34.5422 13.9022 34.7185 14.166 34.8387 14.4563C34.959 14.7466 35.0209 15.0578 35.0209 15.372C35.0209 15.6862 34.959 15.9974 34.8387 16.2877C34.7185 16.578 34.5422 16.8418 34.32 17.064L27.384 24L34.32 30.936C35.232 31.848 35.232 33.384 34.32 34.32Z"
              fill="#FF3D71" />
          </svg>
        </div>

        <div class="success" *ngSwitchCase="'success'">
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M24 0C10.7455 0 0 10.7455 0 24C0 37.2545 10.7455 48 24 48C37.2545 48 48 37.2545 48 24C48 10.7455 37.2545 0 24 0ZM34.4029 19.9418C34.5945 19.7229 34.7403 19.4678 34.8318 19.1916C34.9234 18.9155 34.9587 18.6238 34.9359 18.3338C34.913 18.0438 34.8324 17.7612 34.6987 17.5028C34.565 17.2444 34.3811 17.0154 34.1576 16.8291C33.9341 16.6429 33.6756 16.5032 33.3973 16.4183C33.119 16.3334 32.8266 16.3051 32.5372 16.3349C32.2478 16.3647 31.9673 16.4521 31.7122 16.5919C31.457 16.7318 31.2325 16.9212 31.0516 17.1491L21.6698 28.4051L16.8153 23.5484C16.4038 23.1509 15.8526 22.931 15.2806 22.936C14.7085 22.941 14.1613 23.1704 13.7568 23.5749C13.3522 23.9795 13.1228 24.5267 13.1178 25.0988C13.1128 25.6708 13.3327 26.222 13.7302 26.6335L20.2756 33.1789C20.49 33.3932 20.7467 33.5604 21.0293 33.67C21.3119 33.7795 21.6142 33.829 21.917 33.8152C22.2197 33.8015 22.5163 33.7248 22.7878 33.5901C23.0593 33.4553 23.2997 33.2655 23.4938 33.0327L34.4029 19.9418Z"
              fill="#00D68F" />
          </svg>
        </div>
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.5 63.5H56.5C58.3565 63.5 60.137 62.7625 61.4497 61.4497C62.7625 60.137 63.5 58.3565 63.5 56.5V18L46 0.5H7.5C5.64348 0.5 3.86301 1.2375 2.55025 2.55025C1.2375 3.86301 0.5 5.64348 0.5 7.5V56.5C0.5 58.3565 1.2375 60.137 2.55025 61.4497C3.86301 62.7625 5.64348 63.5 7.5 63.5ZM14.5 7.5H28.5V14.5H35.5V7.5H42.5V21.5H14.5V7.5ZM14.5 35.5H49.5V56.5H14.5V35.5Z"
            fill="white" />
        </svg>
      </div>
      <div class="content">
        <h1 *ngSwitchCase="">Salvar auditoria?</h1>
        <h1 *ngSwitchCase="'success'">Auditoria Salva!</h1>
        <h1 *ngSwitchCase="'error'">Falha no envio.</h1>
      </div>
      <div class="row" *ngSwitchCase="">
        <button class="button" (click)="saveDatas()">Sim</button>
        <button class="button cancel" (click)="toggle()">Não</button>
      </div>
      <button class="button lg" *ngIf="typeContent" (click)="toggle()">
        Confirmar
      </button>
      <!-- <ng-content></ng-content> -->
    </div>
    <div (click)="toggle()" class="overlay" @overlay></div>
  </ng-container>
  <!--   FTP MODAL -->
  <ng-container *ngSwitchCase="'ftpModal'">
    <div class="modal ftp" @modal [ngSwitch]="typeContent">
      <div class="header-ftp">
        <h1 *ngSwitchCase="'ftp'">Selecione o arquivo</h1>
        <h1 *ngSwitchCase="'newAudit'">
          Qual audit. deseja inserir manualmente?
        </h1>
      </div>
      <div class="content">
        <form [formGroup]="form">
          <div class="select" tabindex="-1" *ngSwitchCase="'newAudit'">
            <select formControlName="typeAudit" type="text" class="form-input select" name="typeAudit" id="typeAudit">
              <option value="FLV">FLV</option>
              <option value="Ovos">Ovos</option>
              <option value="Suinos">Suínos</option>
              <option value="Aves">Aves</option>
            </select>
            <label class="form-label" for="typeAudit" [class]="{ focus: form.get('typeAudit')?.value.length > 0 }">
              Tipo de Auditoria
            </label>
            <span class="alert"
              *ngIf="form.get('typeAudit')?.errors && (form.get('typeAudit')?.touched || form.get('typeAudit')?.dirty)">Tipo
              de auditoria obrigatória</span>
          </div>
          <ng-container *ngSwitchCase="'ftp'">
            <input multiple (change)="actionSecodary($event)" type="file" name="uploadFile" id="uploadFile" class="file"
              formControlName="uploadFile" />
            <!-- <span class="alert" *ngIf="
              form.get('uploadFile')?.errors && form.get('uploadFile')?.touched
            ">Arquivo obrigatório</span> -->
          </ng-container>
          <div class="row" *ngIf="typeContent === 'ftp' || typeContent === 'newAudit'">
            <button type="button" class="button"
              (click)="submit()">Confirmar</button>
            <button type="button" class="button cancel" (click)="toggle()">Cancelar</button>
          </div>
        </form>
      </div>
    </div>
    <div (click)="toggle()" class="overlay" @overlay></div>
  </ng-container>
</ng-container>

<div *ngIf="!content"></div>
