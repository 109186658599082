import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProtocolosService } from 'src/app/services/protocolos.service';
import { ModalViewCriteriasComponent } from '../modal-view-criterias/modal-view-criterias.component';

@Component({
  selector: 'app-historico-modal',
  templateUrl: './historico-modal.component.html',
  styleUrls: ['./historico-modal.component.scss']
})
export class HistoricoModalComponent implements OnInit {

  protocols: any;
  protected storage: Storage;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private protocolosService: ProtocolosService,
    public dialogRef: MatDialogRef<HistoricoModalComponent>,
    public dialog: MatDialog) {
    this.storage = window.sessionStorage;
  }

  ngOnInit(): void {
    this.protocolosService.showHistory(this.data).subscribe((r: any) => {
      this.protocols = r;
    })
  }

  closeModal() {
    this.dialogRef.close({ confirm: false });
  }

  openModal(protocol: any) {

    this.dialog.open(ModalViewCriteriasComponent, {
      data: {
        historic: true,
        protocol: protocol
      }
    })
  }

}
