import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-file',
  templateUrl: './modal-file.component.html',
  styleUrls: ['./modal-file.component.scss']
})
export class ModalFileComponent implements OnInit {

  formFile: FormGroup = this.fb.group({
    uploadFile: ['', Validators.required],
  });

  uploadFiles: any = [];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ModalFileComponent>,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    if (this.data.fileTitle) {
      this.formFile.addControl('fileTitle', this.fb.control(null, Validators.required));
    }
  }

  submit() {
    if (this.formFile.valid) {
      if (this.data.fileTitle) {
        this.dialogRef.close({ confirm: true, file: this.uploadFiles, fileTitle: this.formFile.get('fileTitle')?.value });
        return;
      } else {
        this.dialogRef.close({ confirm: true, file: this.uploadFiles });
        return;
      }
    }
  }

  closeModal() {
    this.dialogRef.close({ confirm: false });
  }

  changeFile(event: any) {
    if (event.target.files.length > 0) {
      this.uploadFiles = [];
      for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];
        const fileName = file.name;
        const modifiedFileName = fileName.replace(/[,;]/g, ' ');
        const modifiedFile = new File([file], modifiedFileName, { type: file.type });
        this.uploadFiles.push(modifiedFile);
      }
    }
  }

  onKeyPress(event: KeyboardEvent): void {
    const keyPressed = event.key;
    if (keyPressed === ',' || keyPressed === ';') {
      event.preventDefault();
    }
  }
}
