import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { AfterContentInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { originalOrder } from '../../utils/sortedKeyValue';
import jwtDecode from 'jwt-decode';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterContentInit {

  showVideo: boolean = false;
  token: any;
  showLogin: boolean = false;
  isSafeGis: boolean = false;

  durationInSeconds = 5;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.params['id'];
    if (this.token) {
      sessionStorage.setItem('token', this.token);
      const decodedToken: any = jwtDecode(this.token);

      sessionStorage.setItem('user', JSON.stringify(decodedToken));
      sessionStorage.setItem('loginByToken', JSON.stringify(true));

      if (decodedToken) {
        switch (true) {
          case decodedToken.authorities.includes('ROLE_AUDITINGLEGACY'):
            this.router.navigate(['/auditoria']);
            break;
          case decodedToken.authorities.includes('ROLE_ANALYSISLEGACY'):
            this.router.navigate(['/analises']);
            break;
          case decodedToken.authorities.includes('ROLE_SOCIALAUDITINGLEGACY'):
            this.router.navigate(['/auditoria-social']);
            break;
          case decodedToken.authorities.includes('ROLE_DOCUMENTVIWER'):
            this.router.navigate(['/manuais']);
            break;
          case decodedToken.authorities.includes('ROLE_GEOMONITORING_CLIENT_LEGACY'):
            this.router.navigate(['/safe-gis/solicitacoes']);
            break;
          case decodedToken.authorities.includes('ROLE_GEOMONITORING_CLIENT'):
            this.router.navigate(['/safe-gis/solicitacoes']);
            break;
          case decodedToken.authorities.includes('ROLE_GPA_GEOMONITORING_ADMIN'):
            this.router.navigate(['/safe-gis/solicitacoes']);
            break;
          default:
            this.router.navigate(['/login']);
            break;
        }
      }
    } else {
      const currentUrl = this.router.url;
      if (currentUrl.includes('/safe-gis')) {
        this.isSafeGis = true;
      }
    }
  }

  ngAfterContentInit() {
    this.showVideo = true;
  }

  form: FormGroup = this.fb.group({
    user: ['', Validators.required],
    password: ['', Validators.required],
  });

  submitForm() {
    if (this.form.valid) {
      this.authService.login(this.user.value, this.password.value).subscribe(
        (res) =>{
          this._snackBar.open("Login efetuado com sucesso", undefined, {
            duration: this.durationInSeconds * 1000,
            panelClass: ['alert_success'],
            horizontalPosition: "right",
            verticalPosition: "top"
          });
        }, (error) => {
          if(error == "Erro durante o login"){
            this._snackBar.open("Por favor, verifique seu usuário e senha.", undefined, {
              duration: this.durationInSeconds * 1000,
              panelClass: ['alert_error'],
              horizontalPosition: "center",
              verticalPosition: "bottom"
            });
          }else{
            this._snackBar.open("Necessário alterar a senha.", undefined, {
              duration: this.durationInSeconds * 1000,
              panelClass: ['alert_error'],
              horizontalPosition: "center",
              verticalPosition: "bottom"
            });
          }
        }
      );
    }
  }

  get user() {
    return this.form.get('user') as FormControl;
  }

  get password() {
    return this.form.get('password') as FormControl;
  }


  originalOrder = originalOrder;

  protected show(){
    let input : any = document.querySelector('#password');
    input.setAttribute('type', 'text');
    setTimeout(() => {
      input.setAttribute('type', 'password');
    }, 1000);
  }
}
