<div class="select-container" tabindex="-1">
  <div (click)="openOptions()">
    <input class="form-input select" name="" [placeholder]="placeholder" type="text" [(ngModel)]="value" (change)="filterList($event)" (blur)="filterList($event)" (keyup)="filterList($event)" (focus)="filterList($event)">
    <label class="form-label" for="" [ngClass]="{ focus: value  }"> {{ label }} </label>
    <span class="alert" *ngIf="!valueIsValid() && required && change"> Campo obrigatório </span>
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow" [ngClass]="{ rotate : optionsShow }">
      <path d="M9 1L5 5L1 1" stroke="black" stroke-width="2"/>
    </svg>
  </div>
  <div *ngIf="optionsShow" class="options">
    <div *ngFor="let option of optionsList" class="option" (click)="selectOption(option)">
      {{ option.name }}
    </div>
    <div class="option" *ngIf="optionsList.length === 0" (click)="toggleOptions()">
      Nenhum Registro Encontrado
    </div>
    <!-- <div class="load-more">
      <button type="button" (click)="onLoadMore()">
        <span>Carregar Mais </span>
      </button>
    </div> -->
  </div>
</div>
