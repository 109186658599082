<div class="modal_content">
    <div class="header_modal">
        <h1>Parâmetros de Análise</h1>
        <svg (click)="closeModal()" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"
            fill="none">
            <path
                d="M24.4046 8.90302C25.1985 8.10913 25.1985 6.82198 24.4046 6.02809L23.9719 5.59542C23.178 4.80153 21.8909 4.80153 21.097 5.59542L15 11.6924L8.90302 5.59542C8.10913 4.80153 6.82198 4.80153 6.02809 5.59542L5.59542 6.02809C4.80153 6.82198 4.80153 8.10913 5.59542 8.90302L11.6924 15L5.59542 21.097C4.80153 21.8909 4.80153 23.178 5.59542 23.9719L6.02809 24.4046C6.82198 25.1985 8.10913 25.1985 8.90302 24.4046L15 18.3076L21.097 24.4046C21.8909 25.1985 23.178 25.1985 23.9719 24.4046L24.4046 23.9719C25.1985 23.178 25.1985 21.8909 24.4046 21.097L18.3076 15L24.4046 8.90302Z"
                fill="white" />
        </svg>
    </div>
    <div class="table-area">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="criteriaAnalyzed">
                <th mat-header-cell *matHeaderCellDef> Critérios Analisados </th>
                <td mat-cell *matCellDef="let element">
                    {{element.criterio}}
                </td>
            </ng-container>

            <ng-container matColumnDef="cutOffPeriod">
                <th mat-header-cell *matHeaderCellDef> Período de Corte </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.date ? element.date : '' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="greater">
                <th mat-header-cell *matHeaderCellDef> Área de corte </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.corte">Maior que {{ element.corte }} ha</span>
                </td>
            </ng-container>

            <!-- Cabeçalhos da tabela -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Linhas de dados -->
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <!-- Linha para exibição quando não houver dados -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="3">Nenhum registro encontrado</td>
            </tr>
        </table>


    </div>
    <div class="paginacao">
        <mat-paginator id="paginator" [pageSizeOptions]="[5, 10, 25]" [length]="length" [pageSize]="pageSize"
            [pageIndex]="pageIndex" (page)="listCriteria($event)" showFirstLastButtons>
        </mat-paginator>
    </div>
</div>