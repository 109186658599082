import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProtocolosService } from 'src/app/services/protocolos.service';

@Component({
  selector: 'app-modal-view-criterias',
  templateUrl: './modal-view-criterias.component.html',
  styleUrls: ['./modal-view-criterias.component.scss']
})
export class ModalViewCriteriasComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  protected dataSource = new MatTableDataSource<any>([]);
  protected displayedColumns = [
    'criteriaAnalyzed',
    'cutOffPeriod',
    'greater'
  ];
  filteredDataSource: any;
  protected pageIndex: number = 0;
  protected pageSize: number = 5;
  protected length: number = 0;
  protected loading: boolean = false;

  protected criterias: any = [];

  constructor(
    private dialogRef: MatDialogRef<ModalViewCriteriasComponent>,
    private protocoloService: ProtocolosService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {

    if (this.data?.historic) {
      let protocol: any = this.data.protocol;

      this.criterias = new Array(protocol.criteria.length).fill({ criterio: '', date: '', corte: '', recurrenceDate: '', confirm: '' });

      protocol.criteria.forEach((obj: any, index: any) => {

        let date, area, confirm;
        obj.criteriaParams.forEach((criteriaParam: any) => {
          if (criteriaParam.name == 'YEAR') {
            date = criteriaParam.value;
          }
          else if (criteriaParam.name == 'AREA') {
            area = criteriaParam.value;
          }
          else if (criteriaParam.name == 'EXISTS') {
            confirm = criteriaParam.value;
          }
        })

        this.criterias[index] = { criterio: this.protocoloService.translateCriteria(obj.name), date: date, corte: area, confirm: confirm == true ? 'yes' : 'no', recurrenceDate: obj.period.quantity };

      });

      this.length = this.criterias.length;
      this.dataSource = new MatTableDataSource(this.criterias);
      this.dataSource.paginator = this.paginator;
      this.getFilteredCriterias();
    }
    else {
      this.protocoloService.getProtocolByHashByProvider(this.data.hash, this.data.customerId).subscribe(r => {
        let protocol: any = r;

        this.criterias = new Array(protocol.content[0].criteria.length).fill({ criterio: '', date: '', corte: '', recurrenceDate: '', confirm: '' });

        protocol.content[0].criteria.forEach((obj: any, index: any) => {

          let date, area, confirm;
          obj.criteriaParams.forEach((criteriaParam: any) => {
            if (criteriaParam.name == 'YEAR') {
              date = criteriaParam.value;
            }
            else if (criteriaParam.name == 'AREA') {
              area = criteriaParam.value;
            }
            else if (criteriaParam.name == 'EXISTS') {
              confirm = criteriaParam.value;
            }
          })

          this.criterias[index] = { criterio: this.protocoloService.translateCriteria(obj.name), date: date, corte: area, confirm: confirm == true ? 'yes' : 'no', recurrenceDate: obj.period.quantity };

        });

        this.length = this.criterias.length;
        this.dataSource = new MatTableDataSource(this.criterias);
        this.dataSource.paginator = this.paginator;
      }, (error: any) => {
        this.loading = false;
        let tableData: any = [];
        this.dataSource = new MatTableDataSource(tableData);
      });
      this.getFilteredCriterias();
    }
  }

  protected closeModal() {
    this.dialogRef.close();
  }

  getFilteredCriterias() {
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return data.criterio !== 'Bioma' &&
        data.criterio !== 'Área' &&
        data.criterio !== 'Soja' &&
        data.criterio !== 'Status' &&
        data.criterio !== 'Vegetação';
    };

    this.dataSource.filter = 'apply';
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  listCriteria(pageEvent?: PageEvent): void {
    if (pageEvent) {
      this.pageIndex = pageEvent.pageIndex;
      this.pageSize = pageEvent.pageSize;
    } else {
      this.pageIndex = 0;
      this.pageSize = 15;
    }
  }
}
