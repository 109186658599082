import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from './../../services/app.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  isSidebarPinned = false
  isSafeGis: boolean = false;
  isGpaUserProvider: boolean = false;
  isGpaUser: boolean = false;
  isSafeGisClient: boolean = true;
  user: any;
  storage!: Storage;
  isAdmin: boolean = false;
  isAdminGpa: boolean = false;

  constructor(
    private appService: AppService,
    private router: Router,
  ) {
    this.storage = window.sessionStorage;
    this.user = this.storage.getItem('user');
    this.user = this.user ? JSON.parse(this.user) : {};
    if (this.user.authorities.some((item: string) => item === 'ROLE_GEOMONITORING_ADMIN')) {
      this.isAdmin = true;
    }
    if (this.user.authorities.some((item: string) => item === "ROLE_GPA_GEOMONITORING_ADMIN")) {
      this.isAdminGpa = true;
      this.isSafeGis = true;
    }
  }

  ngOnInit(): void {


    const currentUrl = this.router.url;
    if (currentUrl.includes('/safe-gis')) {
      this.isSafeGis = true;
      return
    }
    if (currentUrl.includes('/safe-gis/client/')) {
      this.isSafeGis = true;
      return
    }

    if (currentUrl.includes('fornecedor')) {
      this.isGpaUserProvider = true;
    }else{
      this.isGpaUser = true;
    }
  }

  toggleSidebarPin() {

    this.appService.toggleSidebarPin();
  }

  toggleArrowSide() {
    if (!this.isSafeGis) {
      this.isSidebarPinned = !this.isSidebarPinned
      this.appService.toggleSidebarPin();
    }
  }

  redirectToGpa() {
    const url = environment.gpaUrl;
    const token = sessionStorage.getItem('token');
    window.open(`${url}/login/${token}`, '_blank');
  }

  rotationArrow() {

    return this.isSidebarPinned ? 'rotate(-180deg)' : ''
  }
}
