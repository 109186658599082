<div class="table-area">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="checkboxBtn" *ngIf="modalRequest">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <mat-slide-toggle [id]="element?.hash" (change)="getProtocolSelected(element)"
                    [checked]="element?.hash === this.protocolSelected?.hash">
                </mat-slide-toggle>
            </td>
        </ng-container>

        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef> Data de Criação </th>
            <td mat-cell *matCellDef="let element">{{ element?.createdAt | date:'dd/MM/yyyy' }} </td>
        </ng-container>

        <ng-container matColumnDef="protocolName">
            <th mat-header-cell *matHeaderCellDef> Nome do Protocolo </th>
            <td mat-cell *matCellDef="let element"> {{ element?.name}} </td>
        </ng-container>
        <ng-container matColumnDef="dataRecorrencia">
            <th mat-header-cell *matHeaderCellDef> Data de Recorrência </th>
            <td mat-cell *matCellDef="let element"> {{ element?.criteria[0]?.period?.quantity }} {{
                element?.criteria[0]?.period?.type == 'DAYS' ? 'dias' : element?.criteria[0]?.period?.type == 'MONTHS' ?
                'meses' :
                element?.criteria[0]?.period?.type == 'YEARS' ? 'anos' : '' }} </td>
        </ng-container>
        <ng-container matColumnDef="isActive" *ngIf="!modalRequest">
            <th mat-header-cell *matHeaderCellDef> Ativo/Inativo </th>
            <td mat-cell *matCellDef="let element" (click)="changeStatusProtocol(element)" style="cursor: pointer;">
                <mat-slide-toggle [checked]="element.active" id="is-active" class="readonly-toggle">
                </mat-slide-toggle>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ações </th>
            <td mat-cell *matCellDef="let element">
                <button *ngIf="!modalRequest" type="button" class="actions-button"
                    (click)="novoProtocolo(element.hash)">
                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.9417 6.16042L11.0458 2.31042L12.3292 1.02708C12.6806 0.675694 13.1123 0.5 13.6244 0.5C14.1365 0.5 14.568 0.675694 14.9187 1.02708L16.2021 2.31042C16.5535 2.66181 16.7368 3.08592 16.7521 3.58275C16.7674 4.07958 16.5993 4.50339 16.2479 4.85417L14.9417 6.16042ZM13.6125 7.5125L3.89583 17.2292H0V13.3333L9.71667 3.61667L13.6125 7.5125Z"
                            fill="#2B81AD" />
                    </svg>
                </button>

                <img src="../../../../../assets/images/olho.png" (click)="showHistory(element.hash)"
                    style="cursor:pointer">
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="9">Nenhum registro encontrado</td>
        </tr>
    </table>

</div>

<div class="paginacao">
    <mat-paginator *ngIf="length > 5" id="paginator" [pageSizeOptions]="[5, 15, 25]" [length]="length"
        [pageSize]="pageSize" [pageIndex]="pageIndex" (page)="listProtocols($event)"
        showFirstLastButtons></mat-paginator>
</div>

<app-spinner *ngIf="loading"></app-spinner>