import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'feature'
})
export class FeaturePipe implements PipeTransform {

  transform(value: any, name: string): unknown {
    let returnFeature = {
      name: '',
      value: '-',
      usage: ''
    };
    if (value.features && value.features.length > 0) {
      value.features.map((feature: any) => {
        if (feature.name === name) {
          returnFeature = feature;
        }
      })
    }
    return returnFeature.value;
  }
}
