import { KeyValue } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() list!: Array<{ name: string, value: string }>;
  @Input() required: boolean = false;
  @Input() label: string = '';
  @Input() initialValue: string = '';
  @Input() placeholder: string = '';

  @Output() changeValue = new EventEmitter();
  @Output() loadMore = new EventEmitter();
  @Output() getSearchOptions = new EventEmitter();
  optionsSelected: string = '';

  value: string = '';
  optionsShow: boolean = false;
  optionsList: Array<{ name: string, value: string }> = [];
  change:boolean = false;
  changeInput: boolean = true;

  constructor(private elementRef: ElementRef) {
    this.optionsList = this.list;
   }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialValue) {
      this.value = this.initialValue;
    }
  }

  ngAfterViewInit(): void {
    this.optionsList = this.list;
  }

  setInitialValue(value: string) {
    this.value = value;
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      if (this.optionsShow) {
        this.toggleOptions();
      }
    }
  }

  toggleOptions() {
    this.optionsShow = !this.optionsShow;
  }

  openOptions() {
    this.toggleOptions();
    this.change = true;
  }

  changeOptionsFilter(optionsList: any) {
    this.optionsList = optionsList;
  }

  valueIsValid():boolean {
    return this.value !== '' && this.optionsList.some(item => item.name === this.value);
  }

  filterList(event: any) {
    this.optionsShow = true;
    if (event.type === 'change') {
      this.changeInput = true;
    }
    if (event.code === 'Enter' && this.changeInput) {
      this.getSearchOptions.emit(this.value);
      this.changeInput = false;
    }

    if (event.type === 'focus' && this.changeInput) {
      this.getSearchOptions.emit({value: this.value, type: blur});
      this.changeInput = false;
    }

  }

  selectOption(option: { name: string, value: string }) {
    this.value = option.name;
    this.optionsSelected = option.value;
    this.changeValue.emit(this.optionsSelected);
    this.optionsShow = false;
  }

  loadMoreOptions() {
    this.loadMore.emit();
  }

  setValue(value: string) {
    this.value = value;
  }

  onLoadMore() {
    this.loadMore.emit(this.value);
  }

}
